import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ImageEditor from './components/ImageEditor';
import CompressImage from './components/CompressImage';
import Header from './components/Header';
import Home from './components/Home';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <Router>
      <div className="App min-h-screen bg-gray-50">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/editor" element={<ImageEditor />} />
          <Route path="/compress" element={<CompressImage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
}

export default App; 