import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="text-xl font-bold text-gray-900">
            ImgEdit.Online
          </Link>
          <nav className="flex space-x-4">
            <Link
              to="/editor"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/editor'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
            >
              Image Editor
            </Link>
            <Link
              to="/compress"
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                location.pathname === '/compress'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
            >
              Compress Image
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header; 