import React from 'react';
import { Link } from 'react-router-dom';
import { 
  PhotoIcon, 
  ScissorsIcon, 
  AdjustmentsHorizontalIcon, 
  ArrowDownTrayIcon,
  SparklesIcon,
  ShieldCheckIcon,
  ArrowsPointingInIcon
} from '@heroicons/react/24/outline';

const Home = () => {
  return (
    <div className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Professional Image Tools Made Simple
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Edit, crop, compress, and enhance your images online with our easy-to-use tools. No signup required!
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {/* Image Editor Card */}
          <Link 
            to="/editor"
            className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center mb-4">
              <PhotoIcon className="h-8 w-8 text-blue-600 mr-3" />
              <h3 className="text-2xl font-semibold">Image Editor</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Powerful image editing tools including cropping, filters, and adjustments. Perfect for creating professional-looking images.
            </p>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <ScissorsIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm">Precise Cropping</span>
              </div>
              <div className="flex items-center">
                <AdjustmentsHorizontalIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm">Advanced Filters</span>
              </div>
            </div>
          </Link>

          {/* Image Compressor Card */}
          <Link 
            to="/compress"
            className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center mb-4">
              <ArrowsPointingInIcon className="h-8 w-8 text-blue-600 mr-3" />
              <h3 className="text-2xl font-semibold">Image Compressor</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Quickly compress images under 100KB while maintaining quality. Perfect for web use and file size requirements.
            </p>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <ArrowDownTrayIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm">Under 100KB</span>
              </div>
              <div className="flex items-center">
                <SparklesIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm">Smart Compression</span>
              </div>
            </div>
          </Link>
        </div>

        {/* Why Choose Us Section */}
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-3xl font-bold text-center mb-8">Why Choose ImgEdit.Online?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex items-start">
              <SparklesIcon className="h-8 w-8 text-blue-600 mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">No Installation</h3>
                <p className="text-gray-600">
                  Access professional image tools directly in your browser. No downloads needed.
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <ShieldCheckIcon className="h-8 w-8 text-blue-600 mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Secure & Private</h3>
                <p className="text-gray-600">
                  Your images are processed locally in your browser. We never store your files.
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <ArrowDownTrayIcon className="h-8 w-8 text-blue-600 mr-4 flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold mb-2">Free to Use</h3>
                <p className="text-gray-600">
                  All tools are completely free to use. No watermarks or hidden fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home; 