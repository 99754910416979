import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageEditor = ({ initialImage, onBack }) => {
  const [selectedImage, setSelectedImage] = useState(initialImage);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
  });
  const [aspectRatio, setAspectRatio] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const imageRef = useRef(null);
  const [isBlackAndWhite, setIsBlackAndWhite] = useState(false);
  const [resizeMode, setResizeMode] = useState('original');
  const [customSize, setCustomSize] = useState({ width: '', height: '' });
  const [croppedDimensions, setCroppedDimensions] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [quality, setQuality] = useState(95);
  const [compressionMode, setCompressionMode] = useState('high');
  const [rotation, setRotation] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [contrast, setContrast] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const [blur, setBlur] = useState(0);
  const [activeTab, setActiveTab] = useState('crop');
  const [sizeUnit, setSizeUnit] = useState('px');
  const [dpi, setDpi] = useState(300);
  const [activeFilter, setActiveFilter] = useState('');
  const [filterIntensity, setFilterIntensity] = useState(100);

  // Common aspect ratios for Indian government forms
  const aspectRatios = [
    { name: 'Free Selection', value: null },
    { name: 'Passport Size (3.5×4.5)', value: 35/45 },
    { name: 'Square (1:1)', value: 1 },
    { name: 'Stamp Size (2×2.5)', value: 20/25 },
    { name: 'Post Card (4×6)', value: 4/6 },
  ];

  // Common preset sizes (in pixels)
  const presetSizes = [
    { name: 'Original Size', value: 'original' },
    { name: 'Passport (3.5×4.5cm)', value: { width: 413, height: 531 } }, // 3.5×4.5cm at 300dpi
    { name: 'Visa (2×2 inch)', value: { width: 600, height: 600 } }, // 2×2 inch at 300dpi
    { name: 'Small (800×600)', value: { width: 800, height: 600 } },
    { name: 'Custom Size', value: 'custom' }
  ];

  // Add compression presets
  const compressionPresets = [
    { name: 'High Quality', value: 'high', quality: 95 },
    { name: 'Medium Quality', value: 'medium', quality: 70 },
    { name: 'Low Quality', value: 'low', quality: 40 },
    { name: 'Custom', value: 'custom' }
  ];

  // Add filter presets
  const filterPresets = [
    { name: 'None', value: '' },
    { name: 'Sepia', value: 'sepia' },
    { name: 'Grayscale', value: 'grayscale' },
    { name: 'Invert', value: 'invert' },
    { name: 'Blur', value: 'blur' }
  ];

  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleAspectRatioChange = (ratio) => {
    setAspectRatio(ratio);
    if (ratio) {
      // When changing aspect ratio, adjust the crop width and height
      setCrop(prev => ({
        ...prev,
        width: 50,
        height: 50 / ratio
      }));

      // Update custom size to match aspect ratio if in custom mode
      if (resizeMode === 'custom' && customSize.width) {
        setCustomSize(prev => ({
          width: prev.width,
          height: Math.round(prev.width / ratio)
        }));
      }
    }
  };

  const getImageStyle = () => {
    let filterString = `brightness(${brightness}%) contrast(${contrast}%) saturate(${saturation}%) blur(${blur}px)`;
    
    switch (activeFilter) {
      case 'sepia':
        filterString += ` sepia(${filterIntensity}%)`;
        break;
      case 'grayscale':
        filterString += ` grayscale(${filterIntensity}%)`;
        break;
      case 'invert':
        filterString += ` invert(${filterIntensity}%)`;
        break;
      case 'blur':
        filterString += ` blur(${filterIntensity / 20}px)`;
        break;
      default:
        break;
    }

    return {
      filter: filterString,
      transform: `rotate(${rotation}deg)`,
    };
  };

  const handleRotateLeft = () => {
    setRotation((prev) => (prev - 90) % 360);
  };

  const handleRotateRight = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleReset = () => {
    setBrightness(100);
    setContrast(100);
    setSaturation(100);
    setBlur(0);
    setRotation(0);
    setActiveFilter('');
    setFilterIntensity(100);
    setIsBlackAndWhite(false);
  };

  const getCroppedImage = () => {
    if (!crop || !imageRef.current) return;

    const canvas = document.createElement('canvas');
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    
    // Determine output size
    let outputWidth = crop.width;
    let outputHeight = crop.height;
    
    if (resizeMode !== 'original' && resizeMode !== 'custom') {
      outputWidth = presetSizes.find(size => size.value === resizeMode)?.value.width || crop.width;
      outputHeight = presetSizes.find(size => size.value === resizeMode)?.value.height || crop.height;
    } else if (resizeMode === 'custom' && customSize.width && customSize.height) {
      outputWidth = parseInt(customSize.width);
      outputHeight = parseInt(customSize.height);
    }

    canvas.width = outputWidth;
    canvas.height = outputHeight;
    
    // Store dimensions for display
    setCroppedDimensions({ width: outputWidth, height: outputHeight });

    const ctx = canvas.getContext('2d');

    // Enable image smoothing for better quality
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Apply image adjustments to canvas
    ctx.filter = getImageStyle().filter;
    
    // Apply rotation if needed
    if (rotation !== 0) {
      ctx.save();
      ctx.translate(canvas.width/2, canvas.height/2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.translate(-canvas.width/2, -canvas.height/2);
    }

    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      outputWidth,
      outputHeight
    );

    if (rotation !== 0) {
      ctx.restore();
    }

    canvas.toBlob(
      (blob) => {
        if (!blob) return;
        const url = URL.createObjectURL(blob);
        setCroppedImage(url);
        setFileSize(blob.size);
      }, 
      'image/jpeg', 
      quality / 100
    );
  };

  // Helper function to convert between units
  const convertSize = (value, fromUnit, toUnit) => {
    if (!value) return '';
    
    // Convert to pixels first
    let pixels = value;
    if (fromUnit === 'cm') {
      pixels = Math.round((value * dpi) / 2.54);
    } else if (fromUnit === 'in') {
      pixels = Math.round(value * dpi);
    }

    // Convert pixels to target unit
    if (toUnit === 'px') {
      return pixels;
    } else if (toUnit === 'cm') {
      return ((pixels * 2.54) / dpi).toFixed(2);
    } else if (toUnit === 'in') {
      return (pixels / dpi).toFixed(2);
    }
  };

  // Update resize mode handler to maintain aspect ratio
  const handleResizeModeChange = (mode) => {
    setResizeMode(mode);
    
    if (mode === 'original') {
      // Reset custom size
      setCustomSize({ width: '', height: '' });
    } else if (mode !== 'custom') {
      // Get preset size
      const preset = presetSizes.find(size => size.value === mode);
      if (preset?.value.width && preset?.value.height) {
        // Update aspect ratio to match preset
        const newRatio = preset.value.width / preset.value.height;
        setAspectRatio(newRatio);
        setCrop(prev => ({
          ...prev,
          width: 50,
          height: 50 / newRatio
        }));
      }
    }
  };

  // Update size change handler
  const handleSizeChange = (dimension, value) => {
    const numValue = parseInt(value);
    
    if (!isNaN(numValue)) {
      // Update custom size
      setCustomSize(prev => {
        const newSize = {
          ...prev,
          [dimension]: numValue
        };

        // If both dimensions are set, update the crop
        if (newSize.width && newSize.height) {
          // Calculate the new aspect ratio
          const newRatio = newSize.width / newSize.height;
          setAspectRatio(newRatio);

          // Update the crop with the new size while maintaining center position
          const imageWidth = imageRef.current?.width || 100;
          const imageHeight = imageRef.current?.height || 100;
          
          // Calculate new crop dimensions as percentages
          const newWidth = (newSize.width / imageWidth) * 100;
          const newHeight = (newSize.height / imageHeight) * 100;
          
          // Get current crop center
          const currentCenterX = crop.x ? crop.x + (crop.width / 2) : 50;
          const currentCenterY = crop.y ? crop.y + (crop.height / 2) : 50;
          
          // Calculate new position to maintain center
          const newX = currentCenterX - (newWidth / 2);
          const newY = currentCenterY - (newHeight / 2);
          
          setCrop({
            unit: '%',
            width: newWidth,
            height: newHeight,
            x: Math.max(0, Math.min(100 - newWidth, newX)),
            y: Math.max(0, Math.min(100 - newHeight, newY))
          });
        }

        return newSize;
      });
    }
  };

  const handleDownload = () => {
    if (croppedImage) {
      const link = document.createElement('a');
      link.href = croppedImage;
      link.download = 'edited-image.jpg';
      link.click();
      setShowPreview(false);
    }
  };

  // Add useEffect for auto-applying changes
  useEffect(() => {
    if (initialImage) {
      setSelectedImage(initialImage);
    }
  }, [initialImage]);

  useEffect(() => {
    if (selectedImage && imageRef.current) {
      getCroppedImage();
    }
  }, [crop, aspectRatio, rotation, brightness, contrast, saturation, blur, isBlackAndWhite, customSize, resizeMode, quality]);

  return (
    <div style={{
      display: 'flex',
      gap: '20px',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }}>
      {/* Left side - Image Display */}
      <div style={{
        flex: '1 1 600px',
        minWidth: '300px',
        maxWidth: '800px'
      }}>
        {!selectedImage ? (
          <div className="upload-container" style={{
            border: '2px dashed #ccc',
            borderRadius: '8px',
            padding: '40px',
            textAlign: 'center',
            backgroundColor: '#f8f8f8',
            cursor: 'pointer'
          }}
          onClick={() => document.getElementById('imageInput').click()}
          >
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <p>Click or drag and drop an image here</p>
          </div>
        ) : (
          <div style={{ 
            minHeight: 'min-content',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start' // Align to top
          }}>
            {!showPreview ? (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                aspect={aspectRatio}
                style={{ maxWidth: '100%' }}
              >
                <img
                  ref={imageRef}
                  src={selectedImage}
                  style={{
                    ...getImageStyle(),
                    maxWidth: '100%',
                    height: 'auto'
                  }}
                  alt="Upload"
                />
              </ReactCrop>
            ) : (
              <div className="preview-container" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
                width: '100%'
              }}>
                <img 
                  src={croppedImage} 
                  alt="Preview" 
                  style={{ 
                    maxWidth: '100%',
                    height: 'auto'
                  }} 
                />
                <div className="preview-actions" style={{ display: 'flex', gap: '10px' }}>
                  <button onClick={handleDownload} className="primary-button">Download</button>
                  <button onClick={() => setShowPreview(false)} className="secondary-button">Back to Edit</button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Right side - Tools */}
      {selectedImage && !showPreview && (
        <div className="editor-tools" style={{
          width: '300px',
          backgroundColor: '#f8f8f8',
          padding: '20px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}>
          <div className="tool-tabs" style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            {['crop', 'adjust', 'filter'].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                style={{
                  flex: 1,
                  padding: '8px',
                  backgroundColor: activeTab === tab ? '#007bff' : '#fff',
                  color: activeTab === tab ? '#fff' : '#000',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>

          {activeTab === 'crop' && (
            <div className="crop-tools">
              <div style={{ marginBottom: '20px' }}>
                <h3 style={{ marginBottom: '10px' }}>Aspect Ratio</h3>
                <select
                  value={aspectRatio || 'free'}
                  onChange={(e) => handleAspectRatioChange(e.target.value === 'free' ? null : parseFloat(e.target.value))}
                  style={{ 
                    width: '100%', 
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #ddd'
                  }}
                >
                  {aspectRatios.map((ratio) => (
                    <option key={ratio.name} value={ratio.value || 'free'}>
                      {ratio.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <h3 style={{ marginBottom: '10px' }}>Custom Size</h3>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <input
                    type="number"
                    placeholder="Width"
                    value={customSize.width}
                    onChange={(e) => handleSizeChange('width', e.target.value)}
                    style={{ 
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ddd'
                    }}
                  />
                  <span>×</span>
                  <input
                    type="number"
                    placeholder="Height"
                    value={customSize.height}
                    onChange={(e) => handleSizeChange('height', e.target.value)}
                    style={{ 
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ddd'
                    }}
                  />
                  <span>px</span>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'adjust' && (
            <div className="adjust-tools">
              <div className="slider-control">
                <label>Brightness</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={brightness}
                  onChange={(e) => setBrightness(e.target.value)}
                />
              </div>
              <div className="slider-control">
                <label>Contrast</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={contrast}
                  onChange={(e) => setContrast(e.target.value)}
                />
              </div>
              <div className="slider-control">
                <label>Saturation</label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={saturation}
                  onChange={(e) => setSaturation(e.target.value)}
                />
              </div>
              <div className="rotation-controls" style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <button onClick={handleRotateLeft}>Rotate Left</button>
                <button onClick={handleRotateRight}>Rotate Right</button>
              </div>
            </div>
          )}

          {activeTab === 'filter' && (
            <div className="filter-tools">
              <h3>Filters</h3>
              <div className="filter-options" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {filterPresets.map((filter) => (
                  <button
                    key={filter.name}
                    onClick={() => {
                      if (activeFilter === filter.value) {
                        setActiveFilter('');
                        setFilterIntensity(100);
                      } else {
                        setActiveFilter(filter.value);
                        setFilterIntensity(100);
                      }
                    }}
                    style={{
                      padding: '8px',
                      backgroundColor: activeFilter === filter.value ? '#007bff' : '#fff',
                      color: activeFilter === filter.value ? '#fff' : '#000',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}
                  >
                    {filter.name}
                  </button>
                ))}
              </div>
              {activeFilter && activeFilter !== 'none' && (
                <div className="filter-intensity" style={{ marginTop: '15px' }}>
                  <label style={{ display: 'block', marginBottom: '5px' }}>Intensity</label>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={filterIntensity}
                    onChange={(e) => setFilterIntensity(parseInt(e.target.value))}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
            </div>
          )}

          <div className="action-buttons" style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <button
              onClick={() => {
                getCroppedImage();
                setShowPreview(true);
              }}
              className="primary-button"
              style={{
                padding: '12px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Preview
            </button>
            <button
              onClick={handleReset}
              className="secondary-button"
              style={{
                padding: '12px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Reset All
            </button>
            <button
              onClick={onBack}
              className="secondary-button"
              style={{
                padding: '12px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Back to Home
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageEditor; 