import React, { useState, useRef, useEffect } from 'react';

const CompressImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [originalSize, setOriginalSize] = useState(0);
  const [compressedSize, setCompressedSize] = useState(0);
  const [quality, setQuality] = useState(80);
  const [isProcessing, setIsProcessing] = useState(false);
  const [targetSize, setTargetSize] = useState('100');
  const [originalDimensions, setOriginalDimensions] = useState(null);
  const [finalDimensions, setFinalDimensions] = useState(null);
  const canvasRef = useRef(null);
  const [isCustomSize, setIsCustomSize] = useState(false);
  const [customSizeInput, setCustomSizeInput] = useState('');
  const [customSizeUnit, setCustomSizeUnit] = useState('KB');
  const [compressionWarning, setCompressionWarning] = useState(null);

  const targetSizeOptions = [
    { label: '10 KB', value: '10' },
    { label: '20 KB', value: '20' },
    { label: '50 KB', value: '50' },
    { label: '100 KB', value: '100' },
    { label: '500 KB', value: '500' },
    { label: '1 MB', value: '1024' },
    { label: 'Custom', value: 'custom' }
  ];

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleImageUpload = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setOriginalSize(file.size);
    const reader = new FileReader();
    reader.onload = (event) => {
      setSelectedImage(event.target.result);
      const img = new Image();
      img.onload = () => {
        setOriginalDimensions({ width: img.width, height: img.height });
        compressImage(event.target.result, false);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const compressImage = async (imageData, withResizing = false) => {
    setIsProcessing(true);
    const img = new Image();
    img.onload = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Calculate dimensions
      let width = img.width;
      let height = img.height;
      const maxDimension = withResizing ? 800 : 1200;
      let scaleFactor = 1;

      // Scale down if needed
      if (withResizing || width > maxDimension || height > maxDimension) {
        const targetBytes = parseInt(targetSize) * 1024;
        const estimatedScaleFactor = withResizing 
          ? Math.min(1, Math.sqrt(targetBytes / originalSize)) 
          : maxDimension / Math.max(width, height);
        
        scaleFactor = estimatedScaleFactor;
        width = Math.floor(width * scaleFactor);
        height = Math.floor(height * scaleFactor);
      }

      canvas.width = width;
      canvas.height = height;

      // Draw and compress image
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);

      // Target size in bytes (with buffer)
      const maxSize = parseInt(targetSize) * 1024;
      const minSize = Math.max(maxSize * 0.7, 5 * 1024);

      // Try different quality settings until file size is within range
      const tryCompress = (minQ, maxQ, attempts = 0) => {
        if (attempts > 10) {
          const compressed = canvas.toDataURL('image/jpeg', minQ);
          const approximateSize = Math.round((compressed.length - 22) * 3 / 4);
          return { compressed, size: approximateSize, quality: Math.round(minQ * 100) };
        }

        const currentQ = (minQ + maxQ) / 2;
        const compressed = canvas.toDataURL('image/jpeg', currentQ);
        const approximateSize = Math.round((compressed.length - 22) * 3 / 4);

        if (approximateSize > maxSize) {
          return tryCompress(minQ, currentQ, attempts + 1);
        } else if (approximateSize < minSize) {
          return tryCompress(currentQ, maxQ, attempts + 1);
        } else {
          return { compressed, size: approximateSize, quality: Math.round(currentQ * 100) };
        }
      };

      const result = tryCompress(0.1, 1.0);
      setCompressedImage(result.compressed);
      setCompressedSize(result.size);
      setQuality(result.quality);
      setFinalDimensions({ width, height });

      // Single warning logic - prioritize most important warning
      if (result.size > maxSize && withResizing && result.quality < 40) {
        // Most severe case: Even with resizing, both size and quality are poor
        setCompressionWarning({
          type: 'severe',
          message: 'Target size cannot be reached without severely degrading image quality. Consider increasing target size.',
          canTryResizing: false
        });
      } else if (result.size > maxSize && !withResizing) {
        // Size issue without trying resizing yet
        setCompressionWarning({
          type: 'size',
          message: result.quality < 50 
            ? 'Target size not reached and quality is low. Try resizing to reduce file size while maintaining better quality.'
            : 'Target size not reached. Try resizing to reduce file size while maintaining quality.',
          canTryResizing: true
        });
      } else if (result.size > maxSize) {
        // Size issue even after resizing
        setCompressionWarning({
          type: 'size',
          message: 'Target size cannot be reached even with resizing. Consider increasing target size.',
          canTryResizing: false
        });
      } else if (result.quality < 50 && !withResizing) {
        // Quality issue but size target met
        setCompressionWarning({
          type: 'quality',
          message: 'Image compressed to target size, but quality is low. Try resizing to maintain better quality.',
          canTryResizing: true
        });
      } else {
        setCompressionWarning(null);
      }

      setIsProcessing(false);
    };
    img.src = imageData;
  };

  const handleDownload = () => {
    if (!compressedImage) return;
    
    const link = document.createElement('a');
    link.href = compressedImage;
    link.download = 'compressed-image.jpg';
    link.click();
  };

  const handleTargetSizeChange = (e) => {
    const newTargetSize = e.target.value;
    if (newTargetSize === 'custom') {
      setIsCustomSize(true);
      return;
    }
    
    setIsCustomSize(false);
    // Reset compression state
    setCompressedImage(null);
    setCompressedSize(0);
    setQuality(80);
    setFinalDimensions(null);
    
    // Set target size and compress in next render
    setTargetSize(newTargetSize);
  };

  const handleCustomSizeSubmit = () => {
    if (!customSizeInput || isNaN(customSizeInput) || parseFloat(customSizeInput) <= 0) {
      alert('Please enter a valid size');
      return;
    }

    let newTargetSize;
    if (customSizeUnit === 'MB') {
      newTargetSize = (parseFloat(customSizeInput) * 1024).toString();
    } else {
      newTargetSize = customSizeInput;
    }

    // Reset compression state
    setCompressedImage(null);
    setCompressedSize(0);
    setQuality(80);
    setFinalDimensions(null);
    
    // Set target size and compress in next render
    setTargetSize(newTargetSize);
  };

  // Add useEffect to handle compression when target size changes
  useEffect(() => {
    if (selectedImage && !isProcessing) {
      compressImage(selectedImage, false);
    }
  }, [targetSize]); // Only run when targetSize changes

  const handleTryWithResizing = () => {
    if (selectedImage) {
      compressImage(selectedImage, true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-8">Smart Image Compression</h1>
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Target Size Selection */}
        <div className="mb-6">
          <h3 className="font-semibold mb-2">Select Target Size</h3>
          <div className="flex flex-wrap gap-2">
            {targetSizeOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => handleTargetSizeChange({ target: { value: option.value } })}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  !isCustomSize && targetSize === option.value
                    ? 'bg-blue-500 text-white'
                    : option.value === 'custom' && isCustomSize
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>

          {/* Custom Size Input */}
          {isCustomSize && (
            <div className="mt-4 flex items-center gap-2">
              <input
                type="number"
                value={customSizeInput}
                onChange={(e) => setCustomSizeInput(e.target.value)}
                placeholder="Enter size"
                className="px-3 py-2 border rounded-md w-32"
                min="0"
                step="0.1"
              />
              <select
                value={customSizeUnit}
                onChange={(e) => setCustomSizeUnit(e.target.value)}
                className="px-3 py-2 border rounded-md"
              >
                <option value="KB">KB</option>
                <option value="MB">MB</option>
              </select>
              <button
                onClick={handleCustomSizeSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Apply
              </button>
            </div>
          )}
        </div>

        {/* Upload Section */}
        <div className="mb-6">
          <div 
            className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:border-blue-500 transition-colors"
            onClick={() => document.getElementById('imageInput').click()}
          >
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            <p className="text-gray-600">Click or drag and drop an image here</p>
            <p className="text-sm text-gray-500 mt-2">Supports JPG, PNG and WebP</p>
          </div>
        </div>

        {/* Preview Section */}
        {(selectedImage || compressedImage) && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Original Image */}
            {selectedImage && (
              <div>
                <h3 className="font-semibold mb-2">Original</h3>
                <img src={selectedImage} alt="Original" className="max-w-full rounded-lg" />
                <div className="mt-2 space-y-1">
                  <p className="text-sm text-gray-600">Size: {formatBytes(originalSize)}</p>
                  {originalDimensions && (
                    <p className="text-sm text-gray-600">
                      Dimensions: {originalDimensions.width} × {originalDimensions.height}px
                    </p>
                  )}
                </div>
              </div>
            )}
            
            {/* Compressed Image */}
            {compressedImage && (
              <div>
                <h3 className="font-semibold mb-2">Compressed</h3>
                <img src={compressedImage} alt="Compressed" className="max-w-full rounded-lg" />
                <div className="mt-2 space-y-1">
                  <p className="text-sm text-gray-600">
                    Target: {formatBytes(parseInt(targetSize) * 1024)}
                  </p>
                  <p className="text-sm text-gray-600">
                    Actual: {formatBytes(compressedSize)}
                  </p>
                  <p className="text-sm text-gray-600">
                    Quality: {quality}%
                  </p>
                  {finalDimensions && (
                    <p className="text-sm text-gray-600">
                      Dimensions: {finalDimensions.width} × {finalDimensions.height}px
                    </p>
                  )}
                  {/* Single Warning Display */}
                  {compressionWarning && (
                    <div className={`mt-4 p-4 rounded ${
                      compressionWarning.type === 'severe' 
                        ? 'bg-red-50' 
                        : compressionWarning.type === 'quality' 
                          ? 'bg-yellow-50' 
                          : 'bg-orange-50'
                    }`}>
                      <div className="space-y-2">
                        <p className={`text-sm ${
                          compressionWarning.type === 'severe' 
                            ? 'text-red-700' 
                            : compressionWarning.type === 'quality' 
                              ? 'text-yellow-700' 
                              : 'text-orange-700'
                        }`}>
                          {compressionWarning.message}
                        </p>
                        {compressionWarning.canTryResizing && (
                          <button
                            onClick={handleTryWithResizing}
                            className={`text-sm ${
                              compressionWarning.type === 'quality' 
                                ? 'bg-yellow-500 hover:bg-yellow-600' 
                                : 'bg-orange-500 hover:bg-orange-600'
                            } text-white px-3 py-1.5 rounded transition-colors`}
                          >
                            Try with Resizing
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* Download Button */}
                {!isProcessing && (
                  <button
                    onClick={handleDownload}
                    disabled={compressionWarning?.type === 'size' || compressionWarning?.type === 'severe'}
                    className={`mt-4 px-4 py-2 rounded transition-colors ${
                      compressionWarning?.type === 'size' || compressionWarning?.type === 'severe'
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                    }`}
                  >
                    Download Compressed Image
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {/* Processing Indicator */}
        {isProcessing && (
          <div className="text-center py-4">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
            <p className="mt-2 text-gray-600">Processing image...</p>
          </div>
        )}
      </div>

      {/* Hidden Canvas */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default CompressImage; 